import styled from "styled-components";
import React from "react";

type IndividualSponsorProps = {
  $index: number;
  $name: string;
  $logo: string;
};

export const IndividualSponsor = (props: IndividualSponsorProps) => (
  <StyledIndividualSponsor className="col-sm-6 col-md-6">
    <img title={props.$name} src={props.$logo} alt={props.$name} />
  </StyledIndividualSponsor>
);

const StyledIndividualSponsor = styled.div`
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  img {
    width: 200px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
